<template>
    <div id="app">
        <div class="body fixed mobile">
            <!-- class: fixed, mobile -->
            <!-- Loading Spinner -->
            <div class="spinner" v-show="loading"><div><div></div></div></div>
            <!-- Header -->
            <section class="header store_register">
                <div class="flexH width">
                    <a class="icon iconButton third" @click.prevent="$router.push('/profile')">
                        <font-awesome-icon
                            icon="fa-solid fa-chevron-left"
                            size="lg"
                        />
                    </a>
                </div>
                <span class="fL nowrap">特約商店申請</span>
                <div class="flexH width right">
                    <!-- <a class="textButton fM third">草稿暫存</a> -->
                </div>
            </section>
            <!-- Main -->
            <section class="main store_register">
                <!-- <div class="steps flexH width middle">
                    <div class="step flexH middle radius active">
                        <span class="fM"
                            >1.<span class="fM"> 營業資訊</span></span
                        >
                    </div>
                    <div class="icon gray">
                        <font-awesome-icon icon="fa-solid angle-right" />
                    </div>
                    <div class="step flexH middle radius">
                        <span class="fM"
                            >2.<span class="fM"> 聯絡人</span></span
                        >
                    </div>
                    <div class="icon gray">
                        <font-awesome-icon icon="fa-solid angle-right" />
                    </div>
                    <div class="step flexH middle radius">
                        <span class="fM"
                            >3.<span class="fM"> 銀行資料</span></span
                        >
                    </div>
                </div> -->
                <ValidationObserver v-slot="{ valid, passes }">
                    <div class="info flexV width padding">
                        <ValidationProvider
                            class="inputBox"
                            rules="banId"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">統一編號</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.banId"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            class="inputBox"
                            rules="required"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">商店招牌名稱</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.name"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                         <ValidationProvider
                            class="inputBox"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">營業登記名稱</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.businessName"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            class="inputBox"
                            rules="required"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">營業電話</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.phone"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            class="inputBox"
                            rules="required"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">營業地址</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.address"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <div class="dropdown rounded">
                            <span class="fM nowrap gray">商店類型</span>
                            <select
                                name="type"
                                class="fM rounded"
                                dir="rtl"
                                v-model="data.storeCategoryId"
                            >
                                <option
                                    v-for="(
                                        storeCategory, index
                                    ) in storeCategories"
                                    :key="'storeCategory' + index"
                                    :value="storeCategory.id"
                                >
                                    {{ storeCategory.name }}
                                </option>
                            </select>
                            <div class="arrow">
                                <font-awesome-icon
                                    icon="fa-solid fa-caret-down"
                                    size="xs"
                                />
                            </div>
                        </div>
                        <!-- Google評價 -->
                        <span class="title fL bold">Google評價</span>
                        <input
                            id="pac-input"
                            type="text"
                            placeholder="搜尋店名"
                            v-model="data.googleStore"
                        />
                        <div class="map" id="map"></div>
                        <ol>
                            <li class="fS gray">
                                在地圖上方輸入框中輸入您的商店名稱
                            </li>
                            <li class="fS gray">
                                在出現的列表中點擊您的商店名稱
                            </li>
                        </ol>
                        <!-- 負責人資訊 -->
                        <span class="title fL bold">負責人資訊</span>
                        <ValidationProvider
                            class="inputBox"
                            rules="required"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">負責人姓名</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.ownerName"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <!-- 聯絡人資訊 -->
                        <span class="title fL bold">聯絡人資訊</span>
                        <ValidationProvider
                            class="inputBox"
                            rules="required"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">聯絡人姓名</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.contactName"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            class="inputBox"
                            rules="required"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">手機號碼</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.contactPhone"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            class="inputBox"
                            rules="required|email"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">信箱</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.contactEmail"
                                    name="Email"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <!-- 提領帳戶 -->
                        <span class="title fL bold">提領帳戶</span>
                        <ValidationProvider
                            class="inputBox"
                            rules="required"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'dropdown',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <span class="fM nowrap gray">銀行代碼</span>
                                <select
                                    name="type"
                                    class="fM rounded"
                                    dir="rtl"
                                    v-model="data.bankCode"
                                >
                                    <option
                                        v-for="(bankOption, index) in bankList"
                                        :key="'bankOption' + index"
                                        :value="bankOption.value"
                                    >
                                        {{ bankOption.value + bankOption.text }}
                                    </option>
                                </select>
                                <div class="arrow">
                                    <font-awesome-icon
                                        icon="fa-solid fa-caret-down"
                                        size="xs"
                                    />
                                </div>
                            </div>
                            <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                        </ValidationProvider>
                        <ValidationProvider
                            class="inputBox"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">分行代碼</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.branchCode"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            class="inputBox"
                            rules="required"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">銀行帳號</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.accountNumber"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            class="inputBox"
                            rules="required"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray">銀行戶名</span>
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.accountName"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <ValidationProvider
                            class="inputBox"
                            rules="required"
                            v-slot="{ errors, invalid }"
                        >
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <span class="fM nowrap gray"
                                    >身分證字號/統一編號</span
                                >
                                <input
                                    type="text"
                                    class="fM"
                                    placeholder=""
                                    v-model="data.idNo"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <!-- 上傳存摺封面 -->
                        <ValidationProvider
                            class="inputBox"
                            rules="required|image"
                            v-slot="{ errors, invalid, validate }"
                        >
                            <span class="title fL bold">上傳存摺封面</span>
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <input
                                    class="file"
                                    type="file"
                                    @change="
                                        uploadBankBookImage($event, validate)
                                    "
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <!-- 上傳統一編號章 -->
                        <ValidationProvider
                            class="inputBox"
                            rules="required|image"
                            v-slot="{ errors, invalid, validate }"
                        >
                            <span class="title fL bold">上傳統一編號章</span>
                            <div
                                :class="[
                                    'input',
                                    'rounded',
                                    { error: invalid && errors[0] }
                                ]"
                            >
                                <!-- class: error -->
                                <input
                                    class="file"
                                    type="file"
                                    @change="uploadBanImage($event, validate)"
                                />
                                <span class="fS error message">{{
                                    errors[0]
                                }}</span>
                            </div>
                        </ValidationProvider>
                        <div class="flexH width center middle">
                            <!-- <a class="button rounded secondary">
                            <span class="fM">上一步</span>
                        </a> -->
                            <a
                                class="button rounded"
                                @click.prevent="formPost(valid, passes)"
                            >
                                <span class="fM">送出</span>
                                <!-- 提交申請 -->
                            </a>
                        </div>
                    </div>
                </ValidationObserver>
            </section>
            <!-- Popup (已提交申請) -->
            <section id="popSuccess" class="popup">
                <div class="popBody">
                    <section class="popHeader">
                        <!-- <span class="fL">Title</span> -->
                    </section>
                    <section class="popMain">
                        <div class="icon success">
                            <font-awesome-icon
                                icon="fa-solid fa-circle-check"
                                size="2x"
                            />
                        </div>
                        <span class="fL">已提交申請</span>
                    </section>
                    <section class="popFooter">
                        <a
                            class="button submit rounded"
                            @click.prevent="$router.push('/profile')"
                        >
                            <span class="fM">確定</span>
                        </a>
                    </section>
                </div>
            </section>
            <!-- Popup (已提交申請) -->
            <section id="popError" class="popup">
                <div class="popBody">
                    <section class="popHeader">
                        <!-- <span class="fL">Title</span> -->
                    </section>
                    <section class="popMain">
                        <div class="icon error">
                            <font-awesome-icon
                                icon="fa-regular fa-circle-xmark"
                                size="2x"
                            />
                        </div>
                        <span class="fL">{{ errorMsg }}</span>
                    </section>
                    <section class="popFooter">
                        <a
                            class="button submit rounded"
                            @click.prevent="popErrorClose()"
                        >
                            <span class="fM">確定</span>
                        </a>
                    </section>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { mapState } from "vuex";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
// import { ValidationProvider, extend, validate } from 'vee-validate';
import { required, email, image } from "vee-validate/dist/rules";

// No message specified.
extend("email", email);
extend("image", {
    ...image,
    message: "請上傳圖片檔案"
});

// Override the default message.
console.log("required: ", required);
extend("required", {
    ...required,
    message: "欄位必填"
});

export default {
    name: "AppointStore",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            apiHost: process.env.VUE_APP_API_HOST,
            storeApiHost: process.env.VUE_APP_STORE_API_HOST,
            merchantId: process.env.VUE_APP_MERCHANT_ID,
            formData: new FormData(),
            data: {
                banId: "",
                name: "",
                businessName: "",
                phone: "",
                address: "",
                storeCategoryId: 1,

                ownerName: "",

                contactName: "",
                contactPhone: "",
                contactEmail: "",

                storePlaceId: "",

                bankCode: "",
                branchCode: "",
                accountNumber: "",
                accountName: "",
                idNo: ""
            },
            isAvailable: true,
            bankOptions: [{ text: "請選擇銀行代碼", value: "" }],
            userMarker: null,
            google: null,
            map: null,
            apiKey: process.env.VUE_APP_GOOGLE_PLACES_API_KEY,
            markers: [],
            bankno: [],
            bankBranchCode: {},
            // bankList: [
            //     {
            //         text: "請選擇銀行代碼",
            //         value: ""
            //     },
            //     {
            //         value: "004",
            //         text: "臺灣銀行"
            //     },
            //     {
            //         value: "005",
            //         text: "臺灣土地銀行"
            //     },
            //     {
            //         value: "006",
            //         text: "合作金庫商業銀行"
            //     },
            //     {
            //         value: "007",
            //         text: "第一商業銀行"
            //     },
            //     {
            //         value: "008",
            //         text: "華南商業銀行"
            //     },
            //     {
            //         value: "009",
            //         text: "彰化商業銀行"
            //     },
            //     {
            //         value: "011",
            //         text: "上海商業儲蓄銀行"
            //     },
            //     {
            //         value: "012",
            //         text: "台北富邦商業銀行"
            //     },
            //     {
            //         value: "013",
            //         text: "國泰世華商業銀行"
            //     },
            //     {
            //         value: "015",
            //         text: "中國輸出入銀行"
            //     },
            //     {
            //         value: "016",
            //         text: "高雄銀行"
            //     },
            //     {
            //         value: "017",
            //         text: "兆豐國際商業銀行"
            //     },
            //     {
            //         value: "021",
            //         text: "花旗(台灣)商業銀行"
            //     },
            //     {
            //         value: "048",
            //         text: "王道商業銀行"
            //     },
            //     {
            //         value: "050",
            //         text: "臺灣中小企業銀行"
            //     },
            //     {
            //         value: "052",
            //         text: "渣打國際商業銀行"
            //     },
            //     {
            //         value: "053",
            //         text: "台中商業銀行"
            //     },
            //     {
            //         value: "054",
            //         text: "京城商業銀行"
            //     },
            //     {
            //         value: "081",
            //         text: "滙豐(台灣)商業銀行"
            //     },
            //     {
            //         value: "101",
            //         text: "瑞興商業銀行"
            //     },
            //     {
            //         value: "102",
            //         text: "華泰商業銀行"
            //     },
            //     {
            //         value: "103",
            //         text: "臺灣新光商業銀行"
            //     },
            //     {
            //         value: "108",
            //         text: "陽信商業銀行"
            //     },
            //     {
            //         value: "118",
            //         text: "板信商業銀行"
            //     },
            //     {
            //         value: "147",
            //         text: "三信商業銀行"
            //     },
            //     {
            //         value: "700",
            //         text: "中華郵政"
            //     },
            //     {
            //         value: "803",
            //         text: "聯邦商業銀行"
            //     },
            //     {
            //         value: "805",
            //         text: "遠東國際商業銀行"
            //     },
            //     {
            //         value: "806",
            //         text: "元大商業銀行"
            //     },
            //     {
            //         value: "807",
            //         text: "永豐商業銀行"
            //     },
            //     {
            //         value: "808",
            //         text: "玉山商業銀行"
            //     },
            //     {
            //         value: "809",
            //         text: "凱基商業銀行"
            //     },
            //     {
            //         value: "810",
            //         text: "星展(台灣)商業銀行"
            //     },
            //     {
            //         value: "812",
            //         text: "台新國際商業銀行"
            //     },
            //     {
            //         value: "815",
            //         text: "日盛國際商業銀行"
            //     },
            //     {
            //         value: "816",
            //         text: "安泰商業銀行"
            //     },
            //     {
            //         value: "822",
            //         text: "中國信託商業銀行"
            //     },
            //     {
            //         value: "823",
            //         text: "將來商業銀行"
            //     },
            //     {
            //         value: "824",
            //         text: "連線商業銀行"
            //     },
            //     {
            //         value: "826",
            //         text: "樂天國際商業銀行"
            //     }
            // ],
            banks: [],
            storeCategories: [],
            loading: false,
            errorMsg: '',
        };
    },
    beforeRouteEnter(to, from, next) {
        // called before the route that renders this component is confirmed.
        // does NOT have access to `this` component instance,
        // because it has not been created yet when this guard is called!
        console.log(to, from);
        next((vm) => {
            let that = vm;
            if (that.user && that.user.userId) {
                //
            } else {
                next("/error");
            }
        });
    },
    computed: {
        ...mapState(["user", "userInfo"]),
        bankList() {
            let bankList = [{
                    text: "請選擇銀行代碼",
                    value: ""
                }]

            if (this.banks) {
                // console.log('banks:', this.banks)
                this.banks.forEach(b =>  {
                    if (!b.branchCode) {
                        bankList.push({
                            text: b.name,
                            value: b.bankCode
                        })
                    }
                })
            }
            bankList.sort(function (a, b) {
                return a.value - b.value;
            });
            return bankList;
        }
    },
    async mounted() {
        
        this.getStoreCategories();
        this.getBankCodes();
        this.getTest();
        /**
         * @license
         * Copyright 2019 Google LLC. All Rights Reserved.
         * SPDX-License-Identifier: Apache-2.0
         */
        // @ts-nocheck TODO(jpoehnelt) remove when fixed
        // This example adds a search box to a map, using the Google Place Autocomplete
        // feature. People can enter geographical searches. The search box will return a
        // pick list containing a mix of places and predicted search terms.
        // This example requires the Places library. Include the libraries=places
        // parameter when you first load the API. For example:
        // <script src="https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places">

        const map = new google.maps.Map(document.getElementById("map"), {
            center: { lat: 24.9742352, lng: 121.0703047 },
            zoom: 13,
            mapTypeId: "roadmap"
        });
        // Create the search box and link it to the UI element.
        const input = document.getElementById("pac-input");

        const searchBox = new google.maps.places.SearchBox(input);
        map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
        // Bias the SearchBox results towards current map's viewport.
        map.addListener("bounds_changed", () => {
            searchBox.setBounds(map.getBounds());
        });

        let markers = [];

        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener("places_changed", () => {
            const places = searchBox.getPlaces();
            console.log(places);

            if (places.length == 0) {
                return;
            }

            // Clear out the old markers.
            markers.forEach((marker) => {
                marker.setMap(null);
            });
            markers = [];

            // For each place, get the icon, name and location.
            const bounds = new google.maps.LatLngBounds();

            places.forEach((place) => {
                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                Object.assign(this.data, {
                    googleStore: place.name
                });
                Object.assign(this.data, {
                    storePlaceId: place.place_id
                });
                Object.assign(this.data, {
                    geometry: JSON.stringify(place.geometry)
                });

                const icon = {
                    url: place.icon,
                    size: new google.maps.Size(71, 71),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(17, 34),
                    scaledSize: new google.maps.Size(25, 25)
                };

                // Create a marker for each place.
                markers.push(
                    new google.maps.Marker({
                        map,
                        icon,
                        title: place.name,
                        position: place.geometry.location
                    })
                );
                console.log({
                    map,
                    icon,
                    title: place.name,
                    position: place.geometry.location
                });
                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }
            });
            this.requestPlaceDetail(map, this.data.storePlaceId)
            map.fitBounds(bounds);
            map.setZoom(16);
            // map.setCenter(markers.getPosition())
        });

        let that = this;
        // Add a rule.
        extend("banId", {
            validate(value) {
                return {
                    required: true,
                    valid: that.isBanFormat(value)
                };
            },
            message: "統編錯誤",
            computesRequired: true
        });
    },
    methods: {
        requestPlaceDetail(map, placeId) {
            const service = new google.maps.places.PlacesService(
                map
            );
            const request = {
                placeId: placeId,
                fields: ["name", "place_id", "geometry", "address_component"]
            };
            service.getDetails(request, (place) => {
                Object.assign(this.data, {
                    addressComponents: JSON.stringify(place.address_components)
                });
            });
        },
        uploadBankBookImage(e, validate) {
            validate(e)
            .then(validateRes => {
                console.log("validateRes: ", validateRes)
                let file = e.target.files[0];
                this.formData.append("bankBookImage", file);
            })
            .catch(() => {
                this.formData.delete("bankBookImage");

            });
        },
        uploadBanImage(e, validate) {
            validate(e)
            .then(validateRes => {
                console.log("validateRes: ", validateRes)
                let file = e.target.files[0];
                this.formData.append("banImage", file);
            })
            .catch(() => {
                this.formData.delete("banImage");

            });

            
        },
        formPost(valid, passes) {
            // console.log("user: ", this.userInfo, qcsso.isLoggedIn());
            console.log("valid: ", valid);
            console.log("passes: ", passes());
            if (!valid) {
                this.errorMsg = '請檢查表單內容是否填寫正確';
                this.popError();
                return;
            }
            if (this.isAvailable && valid) {
                this.data.memberId = this.userInfo.memberId;
                this.data.merchantId = this.merchantId;
                Object.assign(this.data, {
                    memberId: this.userInfo.memberId,
                    merchantId: this.merchantId
                });
                console.log("this.data: ", this.data);
                for (var dkey of Object.keys(this.data)) {
                    console.log("dkey: ", dkey);
                    console.log("value: ", this.data[dkey]);
                    // 20231031 convert null to '' 
                    // because form-data will convert null to string 
                    if (dkey === 'memberId' && this.data[dkey] === 'null') {
                        this.formData.append(dkey, '');
                    } else {
                        this.formData.append(dkey, this.data[dkey]);
                    }
                    
                }
                for (var pair of this.formData.entries()) {
                    console.log(pair[0] + ", " + pair[1]);
                }
                this.isAvailable = false;
                this.loading = true;

                let config = {
                    url: `${this.storeApiHost}/stores/v1/merchants/${this.merchantId}/stores`,
                    method: "POST",
                    "Content-Type": "multipart/form-data",
                    data: this.formData
                };
                this.$http(config)
                    .then((res) => {
                        console.log("res: ", res);
                        this.popSuccess();
                        this.loading = false;
                        this.formData = new FormData();
                    })
                    .catch((err) => {
                        console.log("err: ", err);
                        this.errorMsg = '系統忙碌中，請稍後重試';
                        this.popError();
                        this.isAvailable = true;
                        this.loading = false;
                        this.formData = new FormData();
                    });
            }
        },
        popSuccess() {
            var popSuccess = document.getElementById("popSuccess");
            popSuccess.style.display = "flex";
        },
        popSuccessClose() {
            var popSuccess = document.getElementById("popSuccess");
            popSuccess.style.display = "none";
        },
        popError() {
            var popError = document.getElementById("popError");
            popError.style.display = "flex";
        },
        popErrorClose() {
            var popError = document.getElementById("popError");
            popError.style.display = "none";
        },
        isBanFormat(invoice) {
            let patt = new RegExp(/^\d{8}$/);
            if (invoice && patt.test(invoice)) {
                let sum = 0;
                let result = false;
                // let timeNow = moment().tz('Asia/Taipei').format('YYYY-MM-DD');
                // let newVersionDate = '2023-04-01';
                // let factor = timeNow >= newVersionDate ? 5 : 10;
                let factor = 10;
                if (invoice[6] == 7) {
                    sum =
                        Number(invoice[0]) +
                        Number(invoice[2]) +
                        Number(invoice[4]) +
                        Number(invoice[7]) +
                        Math.floor((2 * Number(invoice[1])) / 10) +
                        Math.floor((2 * Number(invoice[3])) / 10) +
                        Math.floor((2 * Number(invoice[5])) / 10) +
                        (((2 * Number(invoice[1])) % 10) +
                            ((2 * Number(invoice[3])) % 10) +
                            ((2 * Number(invoice[5])) % 10));
                    let sum2 = sum + 1;
                    result = sum % factor == 0 || sum2 % factor == 0;
                } else {
                    sum =
                        Number(invoice[0]) +
                        Number(invoice[2]) +
                        Number(invoice[4]) +
                        Number(invoice[7]) +
                        Math.floor((2 * Number(invoice[1])) / 10) +
                        Math.floor((2 * Number(invoice[3])) / 10) +
                        Math.floor((2 * Number(invoice[5])) / 10) +
                        Math.floor((4 * Number(invoice[6])) / 10) +
                        (((2 * Number(invoice[1])) % 10) +
                            ((2 * Number(invoice[3])) % 10) +
                            ((2 * Number(invoice[5])) % 10) +
                            ((4 * Number(invoice[6])) % 10));
                    result = sum % factor == 0;
                }
                return result;
            } else {
                return false;
            }
        },
        getTest() {
            let request = new XMLHttpRequest();
            request.open("GET", `${process.env.BASE_URL}/bankno.txt`, true);
            request.send(null);
            request.onreadystatechange = () => {
                if (request.readyState === 4 && request.status === 200) {
                    var type = request.getResponseHeader("Content-Type");
                    if (type.indexOf("text") !== 1) {
                        this.bankno = request.responseText
                            .split("\n")
                            .map((d) => d.split(" "));
                        this.bankno.forEach((bank) => {
                            let code = bank[0];
                            let branchCode = bank[1];
                            let bankName = bank[2];
                            if (code && code.length == 3) {
                                if (this.bankBranchCode[code]) {
                                    if (branchCode !== "\t")
                                        this.bankBranchCode[code].push({
                                            value: branchCode.slice(3),
                                            text: bankName
                                        });
                                } else {
                                    if (branchCode !== "\t") {
                                        this.bankBranchCode[code] = [
                                            {
                                                value: branchCode.slice(3),
                                                text: bankName
                                            }
                                        ];
                                        this.bankBranchCode[code].unshift({
                                            text: "請選擇分行代碼",
                                            value: ""
                                        });
                                    }
                                }
                            }
                        });
                        this.bankno.unshift({
                            text: "請選擇銀行代碼",
                            value: ""
                        });
                    }
                }
            };
        },
        callBankCodesAPI() {
            var config = {
                method: 'get',
                url: `${this.apiHost}/stores/v1/bank-codes`,
                headers: { }
            };

            return this.$http(config)
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        callStoreCategoriesAPI() {
            var config = {
                method: 'get',
                url: `${this.apiHost}/stores/v1/store-categories`,
                headers: { }
            };

            return this.$http(config)
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    return response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getStoreCategories() {
            this.callStoreCategoriesAPI()
            .then(res => {
                this.storeCategories = [...res];
            })
            .catch(err => {
                console.log("err: ", err);
                this.$router.push('/error');
            });
        },
        getBankCodes(){
            this.callBankCodesAPI()
            .then(res => {
                if (res.data) {
                    this.banks = res.data;
                }
            })
            .catch(err => {
                console.log("err: ", err);
                this.$router.push('/error');
            });
        }
    }
};
</script>
<style scoped src="@/assets/css/google-map.css"></style>
